import { useContext, useState, useCallback, useEffect } from "react";
import { MapContext } from "../Map";
import RasterLayer from "./RasterLayer";
import Popup from "../popup/EarthEnginePopup";

const { REACT_APP_BASE_URL } = process.env;
const api = REACT_APP_BASE_URL + "earthengine";

const EarthEgnineLayer = (props) => {
  const {
    id,
    maxzoom = 22,
    source: s3Source,
    legendConfig,
    popupTemplate,
  } = props;
  const map = useContext(MapContext);
  const [source, setSource] = useState({
    type: "raster",
    tileSize: 256,
    minzoom: s3Source ? 7 : 0,
    maxzoom,
  });
  const [popup, setPopup] = useState();

  const onClick = useCallback(
    ({ lngLat }) => {
      const { lng, lat } = lngLat;
      setPopup();

      fetch(`${api}?id=${id}&lnglat=${lng},${lat}`)
        .then((response) => response.json())
        .then(({ value }) => setPopup({ lngLat, value }));
    },
    [id]
  );

  useEffect(() => {
    if (!source.tiles) {
      fetch(`${api}?id=${id}`)
        .then((response) => response.json())
        .then((response) => setSource({ ...source, tiles: [response.tileUrl] }))
        .catch(console.error); // TODO
    }

    return () => {
      if (source.tiles) {
        setSource({ ...source, tiles: null });
      }
    };
  }, [id, source]);

  useEffect(() => {
    if (map && source.tiles) {
      map.on("click", onClick);
      map.getCanvas().style.cursor = "pointer";
    }

    return () => {
      if (map && source.tiles) {
        map.off("click", onClick);
        map.getCanvas().style.cursor = "";
      }
    };
  }, [map, source, onClick]);

  return (
    <>
      {s3Source && <RasterLayer {...props} id={`${id}-s3`} source={s3Source} />}
      {source.tiles && (
        <RasterLayer
          {...props}
          id={id}
          source={source}
          legendConfig={s3Source ? null : legendConfig}
        />
      )}
      {popup && (
        <Popup
          {...popup}
          {...popupTemplate}
          items={popupTemplate.categorical ? legendConfig.items : null}
          onClose={() => setPopup()}
        />
      )}
    </>
  );
};

export default EarthEgnineLayer;
