import { useState, useEffect } from "react";
import ResourceWatch from "./ResourceWatch";
import CSV from "./CsvLayer";
import Image from "./ImageLayer";
import Raster from "./RasterLayer";
import Vector from "./VectorLayer";
import Climate from "./ClimateLayer";
import EarthEngine from "./EarthEngineLayer";
import GoogleSpreadsheet from "./GoogleSpreadsheet";
import fetchJson from "../../utils/fetch";

const layerTypes = {
  Raster,
  Vector,
  Image,
  CSV,
  GoogleSpreadsheet,
  EarthEngine,
  ResourceWatch,
  Climate,
};

const Layer = ({ id }) => {
  const [layer, setLayer] = useState();
  const [layerId, subId] = id.split("_");

  useEffect(() => {
    fetchJson(`layers/${layerId}.json`).then(setLayer);
  }, [layerId]);

  if (!layer) {
    return null;
  }

  const Layer = layerTypes[layer.type];

  return Layer ? <Layer {...layer} subLayer={subId} /> : null;
};

export default Layer;
