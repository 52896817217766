import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Popup as PopupGL } from "maplibre-gl";
import { MapContext } from "../Map";
import "./EarthEnginePopup.css";

const popup = new PopupGL({
  className: "verdenskart-popup-earthengine",
});

export const Popup = ({ lngLat, value, label, unit, items, onClose }) => {
  const map = useContext(MapContext);
  const { t } = useTranslation();

  useEffect(() => {
    let text;

    if (value === null) {
      text = t("No value");
    } else if (items) {
      text = t((items.find((i) => i.value === value) || {}).name);
    } else {
      const space = unit.charAt(0) === "%" ? "" : " ";
      text = `${label ? `${t(label)}: ` : ""}${value}${space}${t(unit)}`;
    }

    popup.setText(text).setLngLat(lngLat).on("close", onClose).addTo(map);
    return () => popup.off("close", onClose).remove();
  }, [map, lngLat, value, label, unit, items, onClose, t]);

  return null;
};

export default Popup;
