import { useContext, useState, useEffect } from "react";
import { MapContext } from "../Map";
import Legend from "../legend/Legend";
import {
  addSource,
  addLayer,
  removeSource,
  removeLayer,
} from "../../utils/map";

const { REACT_APP_BASE_URL } = process.env;
const s3tiles = REACT_APP_BASE_URL + "tiles/";

const RasterLayer = (props) => {
  const { id, source, legendConfig } = props;

  const map = useContext(MapContext);
  const [basemap, setBasemap] = useState();

  useEffect(() => {
    // A layer is named landcover in the Carto basemap
    const layerId = id !== "landcover" ? id : "landcovermap";

    addSource(map)(
      id,
      typeof source.tiles === "string"
        ? {
            ...source,
            tiles: [`${s3tiles}${source.tiles}/{z}/{x}/{y}.png`],
          }
        : source
    );
    addLayer(map)(
      {
        id: layerId,
        type: "raster",
        source: id,
        paint: {
          "raster-opacity": 1,
          "raster-resampling": "nearest",
        },
      },
      true
    );

    return () => {
      removeLayer(map)(layerId);
      removeSource(map)(id);
    };
  }, [map, id, source, basemap]);

  useEffect(() => {
    map.on("baselayerchange", setBasemap);
    return () => map.off("baselayerchange", setBasemap);
  }, [map]);

  return legendConfig ? <Legend {...props} /> : null;
};

export default RasterLayer;
