import { useContext, useState, useCallback, useEffect } from "react";
import { MapContext } from "../Map";
import Legend from "../legend/Legend";
import Popup from "../popup/Popup";
import {
  addSource,
  addLayer,
  removeSource,
  removeLayer,
} from "../../utils/map";

const opacityProps = {
  fill: "fill-opacity",
  line: "line-opacity",
};

const VectorLayer = (props) => {
  const {
    id,
    source,
    sourceLayer,
    layers,
    legendConfig,
    popupTemplate,
    opacity = 1,
  } = props;

  const map = useContext(MapContext);
  const [popup, setPopup] = useState();
  const [basemap, setBasemap] = useState();

  const onClick = useCallback(
    (evt) => {
      const { point, lngLat } = evt;

      const layers = map
        .getStyle()
        .layers.filter((layer) => layer.id.includes(id))
        .map((layer) => layer.id);

      const feature = map.queryRenderedFeatures(point, {
        layers: layers,
      })[0];

      if (feature) {
        setPopup({
          lngLat,
          data: feature.properties,
        });
      }
    },
    [map, id]
  );

  useEffect(() => {
    addSource(map)(id, source);

    const { items } = legendConfig || {};

    if (!map.getLayer(id) && items) {
      const expr = items.map(({ filter, color }) => [filter, color]);

      const style = {
        type: "fill",
        "source-layer": sourceLayer,
        paint: {
          "fill-color": ["case", ...expr.flat(), "#fff"],
        },
      };

      addLayer(map)(
        {
          id,
          source: id,
          ...style,
        },
        true
      );
    }

    return () => {
      removeLayer(map)(id);
      removeSource(map)(id);
    };
  }, [id, source, sourceLayer, layers, legendConfig, map, basemap, onClick]);

  useEffect(() => {
    map.on("click", onClick);
    return () => map.off("click", onClick);
  }, [map, onClick]);

  useEffect(() => {
    const { layers } = map.getStyle();

    layers
      .filter((layer) => layer.id.includes(id))
      .forEach(({ id, type }) =>
        map.setPaintProperty(id, opacityProps[type], opacity)
      );
  }, [id, opacity, basemap, map]);

  useEffect(() => {
    map.on("baselayerchange", setBasemap);
    return () => map.off("baselayerchange", setBasemap);
  }, [map]);

  return (
    <>
      {legendConfig && <Legend {...props} />}
      {popup && popupTemplate && <Popup {...popup} template={popupTemplate} />}
    </>
  );
};

export default VectorLayer;
