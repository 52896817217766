import fetchJsonp from "fetch-jsonp";

const getRowValues = (cols) => (row) =>
  cols.reduce((obj, name, index) => ({ ...obj, [name]: row[index] }), {});

const cleanCoord = (coord) => Number(coord.replace(",", "."));

export const fetchDataset = async ({ sheetId, sheetName, key }) => {
  const url = `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}/values/${sheetName}?key=${key}`;

  const { values: rows } = await fetchJsonp(url, {
    jsonpCallback: "callback",
  }).then((response) => response.json());

  const cols = rows.shift();
  const getValues = getRowValues(cols);

  const features = rows.map((row) => {
    const properties = getValues(row);
    const { longitude, latitude } = properties;

    return {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [cleanCoord(longitude), cleanCoord(latitude)],
      },
      properties,
    };
  });

  return {
    type: "FeatureCollection",
    features,
  };
};
