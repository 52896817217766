import { BEFORE_LAYER_IDS } from "../constants/map";

export const addSource = (map) => (id, source) => {
  if (map && !map.getSource(id)) {
    map.addSource(id, source);
  }
};

export const addLayer = (map) => (layer, belowLabels) => {
  if (map && !map.getLayer(layer.id)) {
    let beforeId;

    if (typeof belowLabels === "string") {
      beforeId = belowLabels;
    } else {
      beforeId = belowLabels
        ? BEFORE_LAYER_IDS.find((id) => map.getLayer(id))
        : null;
    }

    map.addLayer(layer, beforeId);
  }
};

export const removeSource = (map) => (id) => {
  if (map && map.getSource(id)) {
    map.removeSource(id);
  }
};

export const removeLayer = (map) => (id) => {
  if (map && map.getLayer(id)) {
    map.removeLayer(id);
  }
};

export const setStyleOptions = {
  diff: false,
};

export const mapStyle = {
  version: 8,
  sources: {},
  layers: [],
  glyphs: "https://fonts.openmaptiles.org/{fontstack}/{range}.pbf",
};

export const maxBounds = [
  [-180, -82],
  [180, 82],
];
