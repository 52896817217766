import { useEffect, useState } from "react";
import GeoJsonLayer from "./GeoJsonLayer";
import Legend from "../legend/Legend";
import { fetchDataset } from "../../utils/csv";

const CsvLayer = (props) => {
  const [data, setData] = useState();
  const { layerId, data: csv, paint, popupTemplate } = props;

  useEffect(() => {
    fetchDataset(csv.file, csv.cols).then(setData);
  }, [csv]);

  if (!data) {
    return null;
  }

  const source = {
    type: "geojson",
    data,
  };

  return (
    <>
      <GeoJsonLayer
        id={layerId}
        source={source}
        paint={paint}
        popupTemplate={popupTemplate}
      />
      <Legend {...props} />
    </>
  );
};

export default CsvLayer;
