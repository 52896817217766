export const DEFAULT_BOUNDS = [
  [-110, -50],
  [120, 70],
];

// export const BEFORE_LAYER_IDS = ["boundary_country_outline", "bing-labels"];
export const BEFORE_LAYER_IDS = ["aeroway-runway", "bing-labels"]; //

export const FIT_BOUNDS_OPTIONS = {
  animate: false,
  padding: { top: 50, bottom: 10, left: 40, right: 10 },
};
