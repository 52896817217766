import { useTranslation } from "react-i18next";
import "./DataControl.css";

const DataControl = ({ layers, index, onClick }) => {
  const { t } = useTranslation();

  return (
    <div className="verdenskart-data-control">
      {layers.map(({ name }, i) => (
        <div
          key={name}
          className={i === index ? "verdenskart-data-control-selected" : ""}
          onClick={() => onClick(i)}
        >
          {t(name)}
        </div>
      ))}
    </div>
  );
};

export default DataControl;
