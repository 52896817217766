import { useEffect, useState } from "react";
import RasterLayer from "./RasterLayer";
import VectorLayer from "./VectorLayer";
import { fetchDataset } from "../../utils/resourcewatch";

// https://resource-watch.github.io/doc-api/tutorials.html
// https://vizzuality.github.io/layer-manager/
// https://github.com/Vizzuality/layer-manager/blob/main/applications/sandbox/src/App.js
// https://github.com/Vizzuality/layer-manager/blob/main/packages/layers-deckgl/src/decoded-layer/index.js
// https://github.com/Vizzuality/layer-manager/blob/main/docs/REACT-INTEGRATION.md
// https://github.com/Vizzuality/layer-manager/pull/51
// https://github.com/mapbox/mapbox-gl-js/issues/6456
// https://docs.mapbox.com/mapbox-gl-js/example/custom-style-layer/

const layerTypes = {
  raster: RasterLayer,
  vector: VectorLayer,
};

const ResourceWatch = (props) => {
  const [layer, setLayer] = useState();
  const { datasetId, layerId } = props;

  useEffect(() => {
    fetchDataset(datasetId).then((data) => {
      const { layerConfig, legendConfig } = data.layer.find(
        (l) => l.id === layerId
      ).attributes;
      const { type, source } = layerConfig;

      if (type === "vector") {
        setLayer({ type, source, legendConfig });
      } else {
        const url = `https://api.resourcewatch.org/v1/layer/${layerId}/tile/gee/{z}/{x}/{y}`;

        setLayer({
          type: "raster",
          source: {
            type: "raster",
            ...source,
            tiles: [url],
            tileSize: 256,
            minzoom: 0,
          },
          legendConfig,
        });
      }
    });
  }, [datasetId, layerId]);

  if (!layer || !layerTypes[layer.type]) {
    return null;
  }

  const { type, source } = layer;
  const LayerCmp = layerTypes[type];

  return (
    <LayerCmp
      {...props}
      legendConfig={props.legendConfig || layer.legendConfig}
      id={layerId}
      source={source}
    />
  );
};

export default ResourceWatch;
