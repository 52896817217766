const A = 6378137.0;
const D2R = Math.PI / 180;

// Project from lng, lat to web mercator coordinates
export const project = ([lng, lat]) => {
  const x = A * lng * D2R;
  const y = A * Math.log(Math.tan(Math.PI * 0.25 + 0.5 * lat * D2R));
  return [x, y];
};

// Translate vounds to coordinates array for canvas source
export const boundsToCoordinates = ([[x1, y1], [x2, y2]]) => [
  [x1, y2],
  [x2, y2],
  [x2, y1],
  [x1, y1],
];

// Returns true if two bbox'es intersects
export const bboxIntersect = (bbox1, bbox2) =>
  !(
    bbox1[0] > bbox2[2] ||
    bbox1[2] < bbox2[0] ||
    bbox1[3] < bbox2[1] ||
    bbox1[1] > bbox2[3]
  );

export const getCellPolygon = (lng, lat) => ({
  type: "Feature",
  geometry: {
    type: "Polygon",
    coordinates: [
      [
        [lng, lat],
        [lng, lat + 1],
        [lng + 1, lat + 1],
        [lng + 1, lat],
        [lng, lat],
      ],
    ],
  },
});
