import { fetchText } from "./fetch";

// TODO: HTTP Error handling
export const fetchDataset = async (file, props) =>
  fetchText(file)
    .then((response) => response.split("\n"))
    .then((response) =>
      response.map((line) => {
        const [id, lng, lat, ...rest] = line.split(";"); // TODO

        return {
          type: "Feature",
          id: Number(id),
          geometry: {
            type: "Point",
            coordinates: [Number(lng), Number(lat)],
          },
          properties: rest.reduce(
            (p, value, index) => ({ ...p, [props[index]]: value }), // TODO
            {}
          ),
        };
      })
    )
    .then((features) => ({
      type: "FeatureCollection",
      features,
    }));
