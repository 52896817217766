import { useTranslation } from "react-i18next";
import "./Choropleth.css";

export const Choropleth = ({ items = [] }) => {
  const { t } = useTranslation();
  const width = 100 / items.length;

  return (
    <div className="verdenskart-legend-choropleth">
      <ul>
        {items.map(({ color }, index) => (
          <li key={index} style={{ width, background: color }} />
        ))}
      </ul>
      <ul>
        {items.map(({ name }, index) => (
          <li key={index} style={{ width }}>
            {name ? t(name) : ""}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Choropleth;
