import { useTranslation } from "react-i18next";
import "./Link.css";

export const Link = ({ label, name, url, urlTranslation, details }) => {
  const { t } = useTranslation();
  let href = url;

  if (urlTranslation) {
    if (url && t(url) !== url) {
      href = t(url);
    } else {
      return null;
    }
  }

  return (
    <div className="verdenskart-link">
      {label && <>{t(label)}: </>}
      {url ? (
        <a href={href} target="_blank" rel="noreferrer">
          {t(name)}
        </a>
      ) : (
        t(name)
      )}
      {details && <> ({t(details)})</>}
    </div>
  );
};

export default Link;
