import { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Popup as PopupGL } from "maplibre-gl";
import { MapContext } from "../Map";
import { roundNumber, percentIncrease, plusSign } from "../../utils/number";
import fetchJson from "../../utils/fetch";
import "./Popup.css";

const popup = new PopupGL({
  className: "verdenskart-popup-climate",
});

export const Popup = ({ lngLat, value, data, template, onClose }) => {
  const { t } = useTranslation();
  const map = useContext(MapContext);
  const [values, setValues] = useState();

  useEffect(() => {
    if (lngLat) {
      const { lng, lat } = lngLat;
      fetchJson(`ipcc/cells/${Math.floor(lng)}/${Math.floor(lat)}.json`) // TODO
        .then(setValues);
    }
  }, [lngLat]);

  useEffect(() => {
    if (lngLat && value && values) {
      const baseName = "Pre-industrial times";
      const { name } = data;
      const oneDecimal = roundNumber(1);
      const twoDecimal = roundNumber(2);
      let caption;
      let baseValue;
      let formattedBaseValue;
      let warmingValue;
      let formattedWarmingValue;
      let changeValue;
      let formattedChangeValue;

      switch (template) {
        case "precipitation":
          caption = "Total precipitation in mm/day";
          baseValue = values["total-precip-1850-1900"];
          formattedBaseValue = `${twoDecimal(baseValue)} ${t("mm")}`;
          warmingValue = value;
          formattedWarmingValue = `${twoDecimal(warmingValue)} ${t("mm")}`;
          changeValue = percentIncrease(baseValue, value);
          formattedChangeValue = `${plusSign(changeValue)}${oneDecimal(
            changeValue
          )}%`;
          break;

        case "temperature-change":
          caption = "Mean temperature";
          baseValue = values["mean-temp-1850-1900"];
          formattedBaseValue = `${oneDecimal(baseValue)}℃`;
          warmingValue = baseValue + value;
          formattedWarmingValue = `${oneDecimal(warmingValue)}℃`;
          changeValue = value;
          formattedChangeValue = `${plusSign(value)}${oneDecimal(value)}℃`;
          break;

        default:
      }

      const content = `
          <table>
            <caption>${t(caption)}</caption>
            <tr>
              <th>${t(baseName)}:</th>
              <td>${formattedBaseValue}</td>
            </tr>
            <tr>
              <th>${t(name)} ${t("global warming")}:</th>
              <td>${formattedWarmingValue}</td>
            </tr>
            <tr>
            <th>${t("Change")}:</th>
            <td>${formattedChangeValue}</td>
          </tr>
          </table>`;

      popup.setMaxWidth("260px").setLngLat(lngLat).addTo(map);
      popup.setHTML(content).on("close", onClose);
    }

    return () => popup.off("close", onClose);
  }, [map, lngLat, value, values, data, template, onClose, t]);

  return null;
};

export default Popup;
