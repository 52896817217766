// https://stackoverflow.com/a/19722641
export const roundNumber = (places) => (number) =>
  +(Math.round(number + "e+" + places) + "e-" + places);

// https://stackoverflow.com/questions/30967552/calculate-change-in-javascript
export const percentIncrease = (a, b) => {
  let percent;
  if (b !== 0) {
    if (a !== 0) {
      percent = ((b - a) / a) * 100;
    } else {
      percent = b * 100;
    }
  } else {
    percent = -a * 100;
  }
  return percent;
};

export const plusSign = (value) => (value > 0 ? "+" : "");
