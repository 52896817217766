import { createContext, useState, useRef, useEffect } from "react";
import { Map, NavigationControl, FullscreenControl } from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import LayerControl from "./ui/LayerControl";
import { mapStyle, maxBounds } from "../utils/map";
import { FIT_BOUNDS_OPTIONS } from "../constants/map";

import "./Map.css";

export const MapContext = createContext();

export const MapGL = ({ bounds, children }) => {
  const [map, setMap] = useState();
  const [mapIsReady, setMapIsReady] = useState(false);
  const mapContainer = useRef();

  useEffect(() => {
    if (!map) {
      const newMap = new Map({
        container: mapContainer.current,
        style: mapStyle,
        maxBounds: maxBounds,
      });

      newMap.scrollZoom.disable();

      newMap.addControl(new NavigationControl({ showCompass: false }));
      newMap.addControl(new FullscreenControl());
      newMap.once("load", () => setMap(newMap));
      newMap.once("baselayerchange", () => setMapIsReady(true));
    }
    return () => {
      if (map) {
        map.remove();
        setMap(null);
        setMapIsReady(false);
      }
    };
  }, [map, mapContainer]);

  useEffect(() => {
    if (map && bounds) {
      map.fitBounds(bounds, FIT_BOUNDS_OPTIONS);
    }
  }, [map, bounds]);

  return (
    <div ref={mapContainer} className="verdenskart-map">
      {map && (
        <MapContext.Provider value={map}>
          {mapIsReady && children}
          <LayerControl />
        </MapContext.Provider>
      )}
    </div>
  );
};

export default MapGL;
