import { useContext, useState, useMemo, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { MapContext } from "../Map";
import ImageLayer from "./ImageLayer";
import Popup from "../popup/ClimatePopup";
import Label from "../label/Label";
import LoadingSpinner from "../ui/LoadingSpinner";
import {
  addSource,
  addLayer,
  removeSource,
  removeLayer,
} from "../../utils/map";
import { getCellPolygon } from "../../utils/geo";
import { oneDecimal } from "../../utils/core";
import getValue from "../../utils/ipcc";

let longitude;
let latitude;

const ClimateLayer = (props) => {
  const { id, layers, bounds, labelTemplate, popupTemplate } = props;
  const { t } = useTranslation();
  const map = useContext(MapContext);
  const [basemap, setBasemap] = useState();
  const [index, setIndex] = useState();
  const [lngLat, setLngLat] = useState();
  const [value, setValue] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const data = layers[index];
  const label = useMemo(() => new Label(), []);

  const getValuePromise = useMemo(() => getValue(data), [data]);

  const onClick = useCallback(
    (evt) =>
      getValuePromise.then((getValue) => {
        setLngLat(evt.lngLat);
        setValue(getValue(evt.lngLat.toArray()));
      }),
    [getValuePromise]
  );

  const onMouseMove = useCallback(
    (evt) => {
      const { lngLat } = evt;
      const lng = Math.floor(lngLat.lng);
      const lat = Math.floor(lngLat.lat);

      if (lng !== longitude || lat !== latitude) {
        longitude = lng;
        latitude = lat;

        label.setLngLat(lngLat);

        getValuePromise.then((getValue) => {
          const value = oneDecimal(getValue([lng, lat]));
          const { space, postfix = "", positive, negative } = labelTemplate;
          let text = `${value}${space ? " " : ""}${t(postfix)}`;

          if (positive && negative) {
            if (value !== 0) {
              text += ` ${t(value > 0 ? positive : negative)}`;
            } else {
              text += ` (${t("no change")})`;
            }
          }

          label.setText(text);
        });

        if (!label.isOpen()) {
          label.addTo(map);
        }

        map.getSource("cell").setData(getCellPolygon(lng, lat));
      }
    },
    [map, label, labelTemplate, getValuePromise, t]
  );

  const onMouseOut = useCallback(() => {
    if (label.isOpen()) {
      label.remove(map);
    }
    map.getSource("cell").setData({
      type: "FeatureCollection",
      features: [],
    });
  }, [map, label]);

  const onPopupClose = useCallback(() => setLngLat(), []);

  useEffect(() => {
    if (lngLat) {
      getValuePromise.then((getValue) => setValue(getValue(lngLat.toArray())));
    }
  }, [lngLat, getValuePromise]);

  useEffect(() => {
    addSource(map)("cell", {
      type: "geojson",
      data: null,
    });

    addLayer(map)({
      id: "cell",
      type: "line",
      source: "cell",
      paint: {
        "line-color": "#000",
      },
    });

    return () => {
      removeLayer(map)("cell");
      removeSource(map)("cell");
    };
  }, [map, id, layers, bounds, data, basemap, t]);

  useEffect(() => {
    map.on("click", onClick);
    map.on("mousemove", onMouseMove);
    map.on("mouseout", onMouseOut);
    map.getCanvas().style.cursor = "pointer";
    return () => {
      map.off("click", onClick);
      map.off("mousemove", onMouseMove);
      map.off("mouseout", onMouseOut);
      map.getCanvas().style.cursor = "";
    };
  }, [map, onClick, onMouseMove, onMouseOut]);

  useEffect(() => {
    map.on("baselayerchange", setBasemap);
    return () => map.off("baselayerchange", setBasemap);
  }, [map]);

  useEffect(() => {
    getValuePromise.then(() => setIsLoading(false));
  }, [getValuePromise]);

  return (
    <>
      <ImageLayer {...props} onIndexChange={setIndex} />
      {popupTemplate && data && data.id !== "1850-1900" && (
        <Popup
          lngLat={lngLat}
          value={value}
          data={data}
          template={popupTemplate}
          onClose={onPopupClose}
        />
      )}
      {isLoading && <LoadingSpinner />}
    </>
  );
};

export default ClimateLayer;
