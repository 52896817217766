import { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { MapContext } from "../Map";
import DataControl from "../ui/DataControl";
import Legend from "../legend/Legend";
import { getCartoOverlays } from "../../utils/carto";
import {
  addSource,
  addLayer,
  removeSource,
  removeLayer,
} from "../../utils/map";
import { boundsToCoordinates } from "../../utils/geo";

const cartoLayers = ["positron", "dark-matter"];

const maxZoom = 5.9;
const defaultZoom = 22;

const ImageLayer = (props) => {
  const { id, subLayer, layers, bounds, legendConfig, onIndexChange } = props;
  const { t } = useTranslation();
  const map = useContext(MapContext);
  const [basemap, setBasemap] = useState();
  const defaultIndex = subLayer
    ? layers.findIndex((l) => l.id === subLayer)
    : layers.length > 4
    ? 1
    : 0;
  const [index, setIndex] = useState(defaultIndex);
  const data = layers[index];

  useEffect(() => {
    const overlays = getCartoOverlays(t);

    layers.forEach(({ image }, index) => {
      const imageId = `${id}-${index}`;
      const url = `${window._verdenskart_base_url || ""}${image}`;

      addSource(map)(imageId, {
        type: "image",
        url,
        coordinates: boundsToCoordinates(bounds),
      });

      addLayer(map)({
        id: imageId,
        type: "raster",
        source: imageId,
        paint: {
          "raster-opacity": 1,
          "raster-resampling": "nearest",
        },
        layout: {
          visibility: "none",
        },
      });
    });

    if (!basemap || cartoLayers.includes(basemap.style)) {
      overlays.forEach((layer) => addLayer(map)(layer));
    }

    map.setMaxZoom(maxZoom);

    return () => {
      removeLayer(map)(id);
      removeSource(map)(id);
      overlays.forEach((layer) => removeLayer(map)(layer.id));

      map.setMaxZoom(defaultZoom);
    };
  }, [map, id, layers, bounds, data, basemap, t]);

  useEffect(() => {
    const imageId = `${id}-${index}`;

    if (map.getLayer(imageId)) {
      map.setLayoutProperty(imageId, "visibility", "visible");
    }
    return () => {
      if (map.getLayer(imageId)) {
        map.setLayoutProperty(imageId, "visibility", "none");
      }
    };
  }, [map, id, index, basemap]);

  useEffect(() => {
    map.on("baselayerchange", setBasemap);
    return () => map.off("baselayerchange", setBasemap);
  }, [map]);

  useEffect(() => {
    if (onIndexChange) {
      onIndexChange(index);
    }
  }, [index, onIndexChange]);

  return (
    <>
      <DataControl layers={layers} index={index} onClick={setIndex} />
      {legendConfig ? <Legend {...props} /> : null}
    </>
  );
};

export default ImageLayer;
