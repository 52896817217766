import LegendItem from "./LegendItem";

export const Basic = ({ items }) => (
  <div className="verdenskart-legend-basic">
    {items.map((item, index) => (
      <LegendItem key={index} {...item} />
    ))}
  </div>
);

export default Basic;
