import { useContext, useState, useRef, useEffect } from "react";
import AnimateHeight from "react-animate-height";
import { useTranslation } from "react-i18next";
import { MapContext } from "../Map";
import Basic from "./Basic";
import Choropleth from "./Choropleth";
import Link from "./Link";
import "./Legend.css";

const legendTypes = {
  basic: Basic,
  choropleth: Choropleth,
  gradient: Choropleth,
};

export const Legend = ({
  name,
  description,
  unit,
  legendConfig = {},
  moreInfo,
  dataSource,
  providedBy,
}) => {
  const map = useContext(MapContext);
  const [height, setHeight] = useState(
    map.getContainer().clientWidth > 1000 ? "auto" : 0
  );
  const { t, i18n } = useTranslation();
  const scrollableEl = useRef(null);

  const { type, items, extra } = legendConfig;
  let LegendItems;
  let ExtraItems;

  if (type && legendTypes[type] && Array.isArray(items)) {
    LegendItems = legendTypes[type];
  }

  if (extra && extra.language === i18n.language) {
    ExtraItems = legendTypes[type];
  }

  // Hack to make legend scrollable on smaller screens
  useEffect(() => {
    if (height === "auto") {
      setTimeout(() => {
        const mapHeight = map.getContainer().clientHeight - 60;
        if (scrollableEl.current.clientHeight > mapHeight) {
          scrollableEl.current.style.height = `${mapHeight}px`;
        }
      }, 200);
    } else {
      scrollableEl.current.style.height = "auto";
    }
  }, [map, scrollableEl, height]);

  return (
    <div className="verdenskart-legend">
      <div
        onClick={() => setHeight(height === 0 ? "auto" : 0)}
        className="verdenskart-legend-header"
      >
        <h2>{t(name)}</h2>
        <div className="verdenskart-legend-toggle">
          {height === 0 ? (
            <>
              {t("Show legend")} <span>∨</span>
            </>
          ) : (
            <>
              {t("Hide legend")} <span>∧</span>
            </>
          )}
        </div>
      </div>
      <div ref={scrollableEl} className="verdenskart-legend-scrollable">
        <AnimateHeight
          id="details"
          height={height}
          contentClassName="verdenskart-legend-content"
        >
          {description && <p>{t(description)}</p>}
          {moreInfo && <Link {...moreInfo} urlTranslation={true} />}
          {unit && <div className="verdenskart-legend-unit">{t(unit)}</div>}
          <div className="verdenskart-legend-columns">
            {LegendItems && <LegendItems items={items} />}
            {ExtraItems && <ExtraItems items={extra.items} />}
          </div>
          {dataSource && <Link label="Source" {...dataSource} />}
          {providedBy && <Link label="Provided by" {...providedBy} />}
        </AnimateHeight>
      </div>
    </div>
  );
};

export default Legend;
