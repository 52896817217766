import { useContext, useState, useMemo, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import bbox from "@turf/bbox";
import { MapContext } from "../Map";
import Popup from "../popup/Popup";
import Label from "../label/Label";
import {
  addSource,
  addLayer,
  removeSource,
  removeLayer,
} from "../../utils/map";

const GeoJsonLayer = ({ id, source, paint, popupTemplate }) => {
  const { t } = useTranslation();
  const map = useContext(MapContext);
  const [popup, setPopup] = useState();
  const [basemap, setBasemap] = useState();

  const label = useMemo(() => new Label(), []);

  const onClick = useCallback(
    (evt) => {
      const { features, lngLat } = evt;
      const [feature] = features;
      const { id, properties, geometry } = feature;
      const { type, coordinates } = geometry;

      setPopup({
        lngLat: type === "Point" ? coordinates : lngLat,
        data: {
          id,
          ...properties,
        },
      });
    },
    [setPopup]
  );

  const onMouseMove = useCallback(
    (evt) => {
      const [feature] = evt.features;
      const { id, properties } = feature;

      label.setHTML(t(properties.name)).setLngLat(evt.lngLat);
      label._id = id;

      if (!label.isOpen() && id !== popup?.data.id) {
        label.addTo(map);
      }

      map.getCanvas().style.cursor = "pointer";
    },
    [map, label, popup, t]
  );

  const onMouseLeave = useCallback(() => {
    label.remove();
    map.getCanvas().style.cursor = "";
  }, [map, label]);

  const onPopupClose = useCallback(() => setPopup(), []);

  useEffect(() => {
    addSource(map)(id, source);
    addLayer(map)({
      id,
      type: "circle",
      source: id,
      paint,
    });

    map.fitBounds(bbox(source.data), {
      padding: 40,
    });

    return () => {
      removeLayer(map)(id);
      removeSource(map)(id);
    };
  }, [map, id, source, paint, basemap]);

  // Close label if popup is opened for same feature
  useEffect(() => {
    if (label.isOpen() && label._id === popup?.data.id) {
      label.remove();
    }
  }, [label, popup]);

  useEffect(() => {
    map.on("click", id, onClick);
    map.on("mousemove", id, onMouseMove);
    map.on("mouseleave", id, onMouseLeave);

    return () => {
      map.off("click", id, onClick);
      map.off("mousemove", id, onMouseMove);
      map.off("mouseleave", id, onMouseLeave);
    };
  }, [map, id, onClick, onMouseMove, onMouseLeave]);

  useEffect(() => {
    map.on("baselayerchange", setBasemap);
    return () => map.off("baselayerchange", setBasemap);
  }, [map]);

  return popup ? (
    <Popup {...popup} template={popupTemplate} onClose={onPopupClose} />
  ) : null;
};

export default GeoJsonLayer;
