var templateRe = /\{ *([\w_ -]+) *\}/g;

export const template = (str, data) =>
  str.replace(templateRe, (str, key) => {
    var value = data[key];

    if (value === undefined) {
      throw new Error("No value provided for variable " + str);
    } else if (typeof value === "function") {
      value = value(data);
    }
    return value;
  });

export const oneDecimal = (value) => Math.round(value * 10) / 10; // TODO: remove
