import { scaleLinear } from "d3-scale";
import { fetchArrayBuffer } from "./fetch";

// Fetch image data and translate back to "true" values
const fetchData = (url, min, max) =>
  fetchArrayBuffer(url)
    .then((data) => new Uint16Array(data))
    .then((data) => {
      const scale = scaleLinear().domain([0, 65535]).range([min, max]);
      const floatData = new Float32Array(data.length);
      data.forEach((v, i) => (floatData[i] = scale(v)));
      return floatData;
    });

// Returns value for lng, lat
const getCellValue = (data) => (lngLat) => {
  if (lngLat) {
    const [lng, lat] = lngLat;
    const row = 89 - Math.floor(lat);
    const col = Math.floor(lng) + 180;
    const index = row * 360 + col;
    return data[index];
  } else {
    console.log(lngLat);
  }
};

const getValue = async (options) => {
  if (options) {
    const { url, min, max } = options;
    const data = await fetchData(url, min, max);
    return getCellValue(data);
  }
};

export default getValue;
