import { Suspense } from "react";
import { render } from "react-dom";
import Router from "./components/app/Router";
import App from "./components/app/App";
import "./utils/i18n";

const elements = document.getElementsByClassName("verdenskart");

for (const el of elements) {
  // Use hash router if only one map on page and no map id is assigned
  const useRouter = elements.length === 1 && !el.dataset.id;
  const Root = useRouter ? Router : App;

  render(
    <Suspense fallback={null}>
      <Root {...el.dataset} />
    </Suspense>,
    el
  );
}

// Makes it possible to change map props from outside
window.verdenskart = function (mapId) {
  let tries = 100;
  return new Promise(function cb(resolve, reject) {
    const mapFunc = window._verdenskart && window._verdenskart[mapId];
    if (!mapFunc && --tries > 0) {
      setTimeout(function () {
        cb(resolve, reject);
      }, 100);
    } else {
      if (mapFunc) {
        resolve(mapFunc);
      } else {
        reject("Map not found");
      }
    }
  });
};
