import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

// https://dev.to/adrai/how-to-properly-internationalize-a-react-application-using-i18next-3hdb
// https://www.i18next.com/overview/configuration-options

const loadPath = `${
  window._verdenskart_base_url || ""
}locales/{{lng}}/{{ns}}.json`;

export default i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    supportedLngs: ["nb", "sv", "da", "fi", "is"],
    fallbackLng: false,
    debug: false,
    backend: { loadPath },
    keySeparator: ">",
    nsSeparator: "|",
  });
