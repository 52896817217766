import { useContext, useCallback, useEffect, useState } from "react";
import { MapContext } from "../Map";
import BasemapSwitcher from "./BasemapSwitcher";
import "./LayerControl.css";

const LayerControl = () => {
  const map = useContext(MapContext);
  const [isOpen, setIsOpen] = useState(false);
  const closeControl = useCallback(() => setIsOpen(false), []);

  useEffect(() => {
    map.on("click", closeControl);
    return () => map.off("click", closeControl);
  }, [map, closeControl]);

  return (
    <div className="verdenskart-layer-control">
      <div
        className="verdenskart-layer-control-toggle"
        onClick={() => setIsOpen(!isOpen)}
      >
        {isOpen ? (
          <div className="verdenskart-layer-control-close">✕</div>
        ) : (
          <div className="verdenskart-layer-control-icon" />
        )}
      </div>
      <BasemapSwitcher isOpen={isOpen} />
    </div>
  );
};

export default LayerControl;
