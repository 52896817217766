import { useState } from "react";
import { useTranslation } from "react-i18next";
import AnimateHeight from "react-animate-height";
import "./LegendItem.css";

// https://github.com/dhis2/maps-app/blob/master/src/components/legend/LegendItem.js
export const LegendItem = ({ type, name, color, outline, info }) => {
  const [height, setHeight] = useState(0);
  const { t } = useTranslation();
  const style = { backgroundColor: color, boxSizing: "border-box" };

  if (type === "circle") {
    style.borderRadius = "50%";
  }

  if (outline) {
    style.borderRadius = "50%";
    style.border = `4px solid ${outline}`;
  }

  return (
    <>
      <div
        className={`verdenskart-legend-item${
          info ? " verdenskart-legend-item-clickable" : ""
        }`}
        onClick={info ? () => setHeight(height === 0 ? "auto" : 0) : undefined}
      >
        <span className="verdenskart-legend-item-color" style={style}></span>
        <span className="verdenskart-legend-item-name">
          {t(name)}{" "}
          {info && (
            <span className="verdenskart-legend-item-info">
              {height === 0 ? "ⓘ" : "✕"}
            </span>
          )}
        </span>
      </div>
      {info && (
        <AnimateHeight height={height}>
          <div className="verdenskart-legend-item-expand">{t(info)}</div>
        </AnimateHeight>
      )}
    </>
  );
};

export default LegendItem;
