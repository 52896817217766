import fetchJsonp from "fetch-jsonp";
import { useContext, useState, useCallback, useEffect } from "react";
import { bboxIntersect } from "../../utils/geo";
import { setStyleOptions } from "../../utils/map";
import { MapContext } from "../Map";
import "./BingLayer.css";

const pixelRatio =
  typeof window !== "undefined" && window.devicePixelRatio >= 2 ? 2 : 1;

const apiKey =
  "QXBCT2dvMVhpOFdqbEI3aFk4WGRWazFvcUFNYTJFVExfMC1ZTGp5QWVNaElXSXItc0NXUThUSUNNZE5DZlFUUQ==";

const layerId = "bing";
const labelsId = "bing-labels";

const fetchMetadata = (url) =>
  fetchJsonp(url, { jsonpCallback: "jsonp" })
    .then((response) => response.json())
    .then(({ brandLogoUri, resourceSets }) => ({
      brandLogoUri,
      ...resourceSets[0].resources[0],
    }))
    .catch(console.error);

const getTileUrls = ({ imageUrl, imageUrlSubdomains }) =>
  imageUrlSubdomains.map(
    (subdomain) =>
      imageUrl.replace("{subdomain}", subdomain) +
      (pixelRatio === 2 ? "&dpi=d2" : "")
  );

const bingLogo = document.createElement("img");
bingLogo.alt = "Bing Maps logo";
bingLogo.className = "verdenskart-bing-logo";

// https://docs.microsoft.com/en-us/bingmaps/rest-services/directly-accessing-the-bing-maps-tiles
const BingLayer = ({ mapStyle = "Aerial", lang = "en-GB" }) => {
  const map = useContext(MapContext);
  const [layer, setLayer] = useState();
  const [labels, setLabels] = useState();
  const isLoaded =
    (mapStyle !== "AerialWithLabelsOnDemand" && layer) || (layer && labels);

  const updateAttribution = useCallback(() => {
    if (layer) {
      const source = map.getSource(layerId);
      const attributionControl = map._controls.find(
        (c) => c._updateAttributions
      );

      if (source && attributionControl) {
        const [lngLat1, lngLat2] = map.getBounds().toArray();
        const mapBbox = [...lngLat1.reverse(), ...lngLat2.reverse()];
        const mapZoom = map.getZoom() < 1 ? 1 : map.getZoom();

        source.attribution = layer.imageryProviders
          .filter(({ coverageAreas }) =>
            coverageAreas.some(
              ({ bbox, zoomMin, zoomMax }) =>
                bboxIntersect(bbox, mapBbox) &&
                mapZoom >= zoomMin &&
                mapZoom <= zoomMax
            )
          )
          .map((p) => p.attribution)
          .join(", ");

        attributionControl._updateAttributions();
      }
    }
  }, [map, layer]);

  useEffect(() => {
    // https://docs.microsoft.com/en-us/bingmaps/rest-services/common-parameters-and-types/supported-culture-codes
    // https://docs.microsoft.com/en-us/bingmaps/rest-services/imagery/get-imagery-metadata
    const key = atob(apiKey);
    let url = `https://dev.virtualearth.net/REST/V1/Imagery/Metadata/${mapStyle}?output=json&include=ImageryProviders&culture=${lang}&key=${key}&uriScheme=https`;

    fetchMetadata(url).then(setLayer);

    if (mapStyle === "AerialWithLabelsOnDemand") {
      fetchMetadata(url + "&ml=Foreground").then(setLabels);
    }
    return () => {
      setLayer();
      setLabels();
    };
  }, [mapStyle, lang]);

  useEffect(() => {
    map.on("moveend", updateAttribution);
    return () => map.off("moveend", updateAttribution);
  }, [map, layer, updateAttribution]);

  useEffect(() => {
    if (isLoaded) {
      map.once("styledata", () =>
        map.fire("baselayerchange", { style: mapStyle })
      );

      const sources = {
        [layerId]: {
          type: "raster",
          tiles: getTileUrls(layer),
          tileSize: 256 / pixelRatio,
        },
      };

      const layers = [
        {
          id: layerId,
          type: "raster",
          source: layerId,
        },
      ];

      if (labels) {
        sources[labelsId] = {
          type: "raster",
          tiles: getTileUrls(labels),
          tileSize: 256 / pixelRatio,
        };

        layers.push({
          id: labelsId,
          type: "raster",
          source: labelsId,
        });
      }

      map.setStyle(
        {
          version: 8,
          sources,
          layers,
          glyphs: "https://fonts.openmaptiles.org/{fontstack}/{range}.pbf",
        },
        setStyleOptions
      );
    }
  }, [map, mapStyle, isLoaded, layer, labels]);

  useEffect(() => {
    const container = map.getContainer();
    if (layer) {
      bingLogo.src = layer.brandLogoUri;
      container.appendChild(bingLogo);
    } else if (
      document.getElementsByClassName("verdenskart-bing-logo").length
    ) {
      container.removeChild(bingLogo);
    }
  }, [map, layer]);

  /*
  if (!layer) {
    return null;
  }

  const { brandLogoUri } = layer;

  return (
    <img
      src={brandLogoUri}
      alt="Bing Maps logo"
      className="verdenskart-bing-logo"
    />
  );
  */

  return null;
};

export default BingLayer;
