import { useContext, useState, useEffect } from "react";
import { MapContext } from "../Map";
import {
  addSource,
  addLayer,
  removeSource,
  removeLayer,
} from "../../utils/map";

const layerId = "feature-outline";

const RasterLayer = ({ feature }) => {
  const map = useContext(MapContext);
  const [basemap, setBasemap] = useState({ style: "positron" });

  useEffect(() => {
    addSource(map)(layerId, {
      type: "geojson",
      data: feature,
    });

    addLayer(map)({
      id: layerId,
      type: "line",
      source: layerId,
      paint: {
        "line-width": 2,
        "line-color": basemap.style === "positron" ? "#333" : "#fff",
        "line-opacity": 0.8,
      },
    });

    return () => {
      removeLayer(map)(layerId);
      removeSource(map)(layerId);
    };
  }, [map, feature, basemap]);

  useEffect(() => {
    map.on("baselayerchange", setBasemap);
    return () => map.off("baselayerchange", setBasemap);
  }, [map, setBasemap]);

  return null;
};

export default RasterLayer;
