import { useState, useEffect } from "react";

window._verdenskart = {};

export const useVerdenskart = (mapId) => {
  const [props, setProps] = useState();

  useEffect(() => {
    if (mapId) {
      window._verdenskart[mapId] = setProps;
    }
  }, [mapId]);

  return props;
};
