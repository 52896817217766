// Appends base url if set
const addBaseUrl = (url) => `${window._verdenskart_base_url || ""}${url}`;

export const fetchJson = (url) =>
  fetch(addBaseUrl(url)).then((response) => response.json());

export const fetchText = (url) =>
  fetch(addBaseUrl(url)).then((response) => response.text());

export const fetchArrayBuffer = (url) =>
  fetch(addBaseUrl(url)).then((response) => response.arrayBuffer());

export default fetchJson;
