import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Map from "../Map";
import Layer from "../layers/Layer";
import FeatureOutline from "../layers/FeatureOutline";
import { useVerdenskart } from "../../hooks/verdenskart";
import { DEFAULT_BOUNDS } from "../../constants/map";
import fetchJson from "../../utils/fetch";
import "./App.css";

const App = (props) => {
  const verdenskart = useVerdenskart(props.id); // from calling app
  const [feature, setFeature] = useState();
  const { i18n } = useTranslation();

  const { lang, layer, country } = { ...props, ...verdenskart };

  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang);
    }
  }, [i18n, lang]);

  useEffect(() => {
    if (country) {
      fetchJson(`countries/${country}.geojson`)
        .then(setFeature)
        .catch((error) => console.error(error));
    } else {
      setFeature();
    }
  }, [country]);

  return (
    <div className="verdenskart-app">
      <Map bounds={feature ? feature.bbox : DEFAULT_BOUNDS}>
        {layer && <Layer id={layer} />}
        {feature && <FeatureOutline feature={feature} />}
      </Map>
    </div>
  );
};

export default App;
