import { ocean, continent, country, place } from "./cartoNames.json";

const labelLayers = [
  {
    id: "watername_ocean",
    field: "name",
    labels: ocean,
  },
  {
    id: "place_continent",
    field: "name_en",
    labels: continent,
  },
  {
    id: "place_country_1",
    field: "name_en",
    labels: country,
  },
  {
    id: "place_country_2",
    field: "name_en",
    labels: country,
  },
  {
    id: "place_capital_dot_z7",
    field: "name_en",
    labels: place,
  },
  {
    id: "place_city_r6",
    field: "name_en",
    labels: place,
  },
  {
    id: "place_city_r5",
    field: "name_en",
    labels: place,
  },
  {
    id: "place_city_dot_r4",
    field: "name_en",
    labels: place,
  },
  {
    id: "place_city_dot_r2",
    field: "name_en",
    labels: place,
  },
  {
    id: "place_city_dot_z7",
    field: "name_en",
    labels: place,
  },
];

const getExpr = (labels, field, t) =>
  ["match", ["get", field]].concat(
    labels
      .filter((name) => name !== t(name))
      .map((name) => [name, t(name)])
      .flat(),
    [["get", field]]
  );

const translateStyle = (style, t) => {
  labelLayers.forEach(({ id, field, labels }) => {
    const layer = style.layers.find((l) => l.id === id);
    const expr = getExpr(labels, field, t);

    if (layer && expr.length > 3) {
      layer.layout["text-field"] = expr;
    }
  });

  return style;
};

export const getCartoStyle = (url, t) =>
  fetch(url)
    .then((response) => response.json())
    .then((style) => translateStyle(style, t));

// Target Somaliland border
const withinSomalia = [
  "within",
  {
    type: "Polygon",
    coordinates: [
      [
        [47.9, 7.9],
        [49, 7.9],
        [49, 11.3],
        [47.9, 11.3],
        [47.9, 7.9],
      ],
    ],
  },
];

const textFont = [
  "Montserrat Medium",
  "Open Sans Bold",
  "Noto Sans Regular",
  "HanWangHeiLight Regular",
  "NanumBarunGothic Regular",
];

const textStyle = {
  "text-color": "#000",
};

const lineStyle = {
  "line-color": "#333",
  "line-opacity": ["case", withinSomalia, 0, 0.3],
  "line-width": {
    stops: [
      [3, 1],
      [6, 1.5],
    ],
  },
};

export const getCartoOverlays = (t) => [
  {
    id: "water_overlay",
    type: "line",
    source: "carto",
    "source-layer": "water",
    paint: lineStyle,
  },
  {
    id: "boundary_country_inner_overlay",
    type: "line",
    source: "carto",
    "source-layer": "boundary",
    minzoom: 0,
    filter: ["all", ["==", "admin_level", 2], ["==", "maritime", 0]],
    layout: { "line-cap": "round", "line-join": "round" },
    paint: lineStyle,
  },
  {
    id: "watername_ocean_overlay",
    type: "symbol",
    source: "carto",
    "source-layer": "water_name",
    minzoom: 0,
    maxzoom: 5,
    filter: [
      "all",
      ["has", "name"],
      ["==", "$type", "Point"],
      ["==", "class", "ocean"],
    ],
    layout: {
      "text-field": getExpr(ocean, "name", t),
      "symbol-placement": "point",
      "text-size": {
        stops: [
          [0, 13],
          [2, 14],
          [4, 18],
        ],
      },
      "text-font": [
        "Montserrat Medium Italic",
        "Open Sans Italic",
        "Noto Sans Regular",
        "HanWangHeiLight Regular",
        "NanumBarunGothic Regular",
      ],
      "text-line-height": 1.2,
      "text-padding": 2,
      "text-allow-overlap": false,
      "text-ignore-placement": false,
      "text-pitch-alignment": "auto",
      "text-rotation-alignment": "auto",
      "text-max-width": 6,
      "text-letter-spacing": 0.1,
    },
    paint: textStyle,
  },
  {
    id: "place_continent_overlay",
    type: "symbol",
    source: "carto",
    "source-layer": "place",
    minzoom: 0,
    maxzoom: 2,
    filter: ["all", ["==", "class", "continent"]],
    layout: {
      "text-field": getExpr(continent, "name_en", t),
      "text-font": textFont,
      "text-transform": "uppercase",
      "text-size": 14,
      "text-letter-spacing": 0.1,
      "text-max-width": 9,
      "text-justify": "center",
      "text-keep-upright": false,
    },
    paint: textStyle,
  },
  {
    id: "place_country_2_overlay",
    type: "symbol",
    source: "carto",
    "source-layer": "place",
    minzoom: 3,
    maxzoom: 10,
    filter: [
      "all",
      ["==", "class", "country"],
      [">=", "rank", 3],
      ["has", "iso_a2"],
    ],
    layout: {
      "text-field": getExpr(country, "name_en", t),
      "text-font": textFont,
      "text-size": {
        stops: [
          [3, 10],
          [5, 11],
          [6, 12],
          [7, 13],
          [8, 14],
        ],
      },
      "text-transform": "uppercase",
    },
    paint: textStyle,
  },
  {
    id: "place_country_1_overlay",
    type: "symbol",
    source: "carto",
    "source-layer": "place",
    minzoom: 2,
    maxzoom: 7,
    filter: ["all", ["==", "class", "country"], ["<=", "rank", 2]],
    layout: {
      "text-field": getExpr(country, "name_en", t),
      "text-font": textFont,
      "text-size": {
        stops: [
          [3, 11],
          [4, 12],
          [5, 13],
          [6, 14],
        ],
      },
      "text-transform": "uppercase",
      "text-max-width": {
        stops: [
          [2, 6],
          [3, 6],
          [4, 9],
          [5, 12],
        ],
      },
    },
    paint: textStyle,
  },
  {
    id: "place_city_dot_r4_overlay",
    type: "symbol",
    source: "carto",
    "source-layer": "place",
    minzoom: 5,
    maxzoom: 7,
    filter: ["all", ["==", "class", "city"], ["<=", "rank", 4]],
    layout: {
      "text-field": getExpr(place, "name_en", t),
      "text-font": textFont,
      "text-size": 12,
      "icon-image": "circle-11",
      "icon-offset": [16, 5],
      "text-anchor": "right",
      "icon-size": 0.4,
      "text-max-width": 8,
      "text-keep-upright": true,
      "text-offset": [0.2, 0.2],
    },
    paint: {
      ...textStyle,
      "icon-color": "#333",
      "icon-translate-anchor": "map",
    },
  },
  {
    id: "place_city_dot_r2_canvas",
    type: "symbol",
    source: "carto",
    "source-layer": "place",
    minzoom: 4,
    maxzoom: 7,
    filter: ["all", ["==", "class", "city"], ["<=", "rank", 2]],
    layout: {
      "text-field": [
        "match",
        ["get", "name_en"],
        "Copenhagen",
        "København",
        ["get", "name_en"],
      ],
      "text-font": textFont,
      "text-size": 12,
      "icon-image": "circle-11",
      "icon-offset": [16, 5],
      "text-anchor": "right",
      "icon-size": 0.4,
      "text-max-width": 8,
      "text-keep-upright": true,
      "text-offset": [0.2, 0.2],
    },
    paint: {
      ...textStyle,
      "icon-color": "#333",
      "icon-translate-anchor": "map",
    },
  },
];

export const hideSomalilandBorder = (map) => {
  map.setPaintProperty("boundary_country_inner", "line-opacity", [
    "case",
    withinSomalia,
    0,
    1,
  ]);
};
