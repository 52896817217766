import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { setStyleOptions } from "../../utils/map";
import { getCartoStyle, hideSomalilandBorder } from "../../utils/carto";
import { MapContext } from "../Map";

const CartoLayer = ({ mapStyle = "positron" }) => {
  const map = useContext(MapContext);
  const { t, i18n } = useTranslation();
  const { language } = i18n;

  useEffect(() => {
    getCartoStyle(
      `https://basemaps.cartocdn.com/gl/${mapStyle}-gl-style/style.json`,
      t
    ).then((style) => {
      map.once("styledata", () => {
        hideSomalilandBorder(map, mapStyle);

        map.fire("baselayerchange", {
          style: mapStyle,
        });
      });

      map.setStyle(style, setStyleOptions);
    });
  }, [map, mapStyle, language, t]);

  return null;
};

export default CartoLayer;
