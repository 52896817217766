import { useState } from "react";
import { useTranslation } from "react-i18next";
import CartoLayer from "../layers/CartoLayer";
import BingLayer from "../layers/BingLayer";
import "./BasemapSwitcher.css";

const layers = [
  {
    id: "positron",
    name: "Light",
    Layer: CartoLayer,
    image: "images/light.png",
  },
  {
    id: "dark-matter",
    name: "Dark",
    Layer: CartoLayer,
    image: "images/dark.png",
  },
  {
    id: "Aerial",
    name: "Aerial",
    Layer: BingLayer,
    image: "images/aerial.jpg",
  },
  {
    id: "AerialWithLabelsOnDemand",
    name: "Aerial with labels",
    Layer: BingLayer,
    image: "images/aerial-label.jpg",
  },
];

const BasemapSwitcher = ({ isOpen }) => {
  const { t } = useTranslation();
  const [basemap, setBasemap] = useState("positron");
  const layer = layers.find((l) => l.id === basemap);

  return (
    <>
      {isOpen && (
        <div className="verdenskart-basemap-switcher">
          <h2>{t("Basemap")}</h2>
          <div className="verdenskart-basemap-switcher-layers">
            {layers.map(({ id, name, image }) => (
              <div
                key={id}
                className={`verdenskart-basemap-${id}`}
                onClick={id === basemap ? undefined : () => setBasemap(id)}
              >
                <img
                  src={`${window._verdenskart_base_url || ""}${image}`}
                  alt={name}
                  className={
                    id === basemap
                      ? "verdenskart-basemap-switcher-selected"
                      : undefined
                  }
                />
                <span>{t(name)}</span>
              </div>
            ))}
          </div>
        </div>
      )}
      {layer && <layer.Layer mapStyle={layer.id} lang={t("en-GB")} />}
    </>
  );
};

export default BasemapSwitcher;
