import { HashRouter } from "react-router-dom";
import UseRouter from "./UseRouter";

const Router = (props) => (
  <HashRouter>
    <UseRouter {...props} />
  </HashRouter>
);

export default Router;
