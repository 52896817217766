import { useContext, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Popup as PopupGL } from "maplibre-gl";
import { MapContext } from "../Map";
import { template } from "../../utils/core";
import "./ClimatePopup.css";

const popup = new PopupGL({
  className: "verdenskart-popup",
});

// Show local hosted image for biosphere reserve, geopark and intangible heritage
const hasLocalImage = ({ category }) => "BGI".includes(category);

const getContent = (
  data,
  { title, items = [], link, moreInfo, image, localImage },
  t
) => {
  const url = link ? t(data.link) || template(link, data) : null;

  let content = url
    ? `<a href="${url}" target="_blank" rel="noreferrer">`
    : "<div>";

  if (image) {
    let url = template(image, data);

    if (hasLocalImage(data)) {
      url = template(localImage, data);
    }

    content += `<img src="${url}"/>`;
  }

  if (title && data[title]) {
    content += `<h2>${t(data[title])}</h2>`;
  }

  items.forEach(({ key, name }) => {
    content += `<div>${t(name)}: ${t(data[key])}`;
  });

  if (moreInfo) {
    const href = template(moreInfo.url, data);

    content += `<a href="${href}" target="_blank" rel="noreferrer">${t(
      moreInfo.name
    )}</a>`;
  }

  content += url ? "</a>" : "</div>";

  return content;
};

export const Popup = ({ lngLat, data, template, onClose }) => {
  const { t } = useTranslation();
  const map = useContext(MapContext);

  const content = useMemo(
    () => getContent(data, template, t),
    [data, template, t]
  );

  useEffect(() => {
    const { className = "popup-default" } = template;

    popup
      .setMaxWidth("300px")
      .setHTML(content)
      .setLngLat(lngLat)
      .addTo(map)
      .on("close", onClose);

    popup.addClassName(className);

    return () => {
      popup.removeClassName(className);
      popup.off("close", onClose);
      popup.remove();
    };
  }, [map, lngLat, content, template, onClose]);

  return null;
};

export default Popup;
